import { useUser } from '~~/store/user';
// import { CompanyProfilePermissionType } from '~~/types/permission';

export default defineNuxtRouteMiddleware(async () => {
  if (process.client) {
    const { $pinia } = useNuxtApp();
    const user = useUser($pinia);
    await user.isModuleReady();

    // To-DO
    // const hasAccess =
    //   user.userType === 'candidate' ||
    //   user.hasPermissionTo({
    //     canSee: [CompanyProfilePermissionType.SOCIAL_GENERAL],
    //     canView: [CompanyProfilePermissionType.SOCIAL_GENERAL],
    //     canEdit: [CompanyProfilePermissionType.SOCIAL_GENERAL],
    //     canDelete: [CompanyProfilePermissionType.SOCIAL_GENERAL],
    //   });

    const hasAccess = true;

    if (!hasAccess) {
      await navigateTo('/dashboard');
      useToast().warning(
        `Oooops... You don't have access to messaging`,
        {
          timeout: 5000,
          id: 'messaging-middleware',
        },
      );
      return false;
    }
  }
});
